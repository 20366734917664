import React, { Fragment } from 'react';
import { SxStyleProp } from 'theme-ui';

import { ClassNameProps, SVGElementProps } from '@ComponentProps';
import { loadable } from '@Core/DontHydrateLoadableLib';
import { DynamicSvgProps } from '@DesignSystemComponents/DesignSystemAsset/DesignSystemAsset';
import { IconOutlet } from '@DesignSystemComponents/DesignSystemAsset/IconOutlet';

interface IconProps extends ClassNameProps, SVGElementProps {
  name: string;
  wrapperStyles?: SxStyleProp;

  /**
   * @deprecated: Wrap the component with TriggerButton.
   */
  onClick?: undefined;
}

/**
 * Component to render icons stored in Sunrise.
 *
 * @deprecated: Use design system icons instead.
 */
export const Icon: React.FC<IconProps> = ({ name, width, height, role, className, ...rest }) => {
  const DynamicIcon = loadable.lib(() => import(`@Icons/${name}.svg`), {
    ...rest,
    wrapperStyles: {
      lineHeight: 0,
      ...rest.wrapperStyles,
    },
  }) as React.FC<DynamicSvgProps>;

  return (
    <DynamicIcon>
      {({ viewBox, svg }) => (
        <Fragment>
          <IconOutlet
            name={name}
            svg={svg}
          />

          <svg
            viewBox={viewBox}
            className={className}
            width={width}
            height={height}
            role={role}
          >
            <use href={`#${name}`} />
          </svg>
        </Fragment>
      )}
    </DynamicIcon>
  );
};
